<template>
    <div class="login">
        <!-- <div> {{ this.third_id }}</div>
        <div> {{ this.code }}</div>
        <el-button @click="getWechatCode"> Code </el-button>
        <el-button @click="login"> login</el-button> -->
    </div>
</template>

<script>
export default {
    data () {
        return {
            // weixin code
            wechatCode: '',
            // ID
            third_id: '',
            // appId
            appid: 'wx54b6e8fbbef807e5',
            // 待修改, 测试 appid
            // appid: 'wx79a5f69b8fe3b9ef',
            // weChat 回调地址
            redirect_uri: process.env.VUE_APP_REDIRECT_URI
        }
    },

    beforeMount () {
        const { third_id, code } = this.$route.query
        Object.assign(this, {
            'wechatCode': code,
            third_id
        })
        localStorage.setItem('thirdId', third_id)
        !code ? this.getWechatCode() : this.login()
    },

    methods: {
        // 登录
        login: async function () {
            try {
                await this.$store.dispatch('user/login', { platform: 'wechat', code: this.wechatCode })
                this.$message.success('登录成功')
                this.$router.replace({ name: 'MainPage', query: { third_id: this.third_id }})
            } catch (err) {
                this.$message.error('登录失败')
            }
        },
        // 获取微信 code
        getWechatCode: function () {
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.redirect_uri}/login?third_id=${this.third_id}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
            // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx79a5f69b8fe3b9ef&redirect_uri=http://cskh.newcigar.cn/login?third_id=${this.third_id}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        }
    }
}
</script>

<style lang="scss" scoped></style>
